#home{
    position: relative;
    background: url('../../assets/bgIMG.png');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper {
        padding: 0;

        .copyright{
            display: none;
        }
    }
}

.app__header{
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 0rem 0rem;

    @media screen and (min-width: 2000px){
        padding-top: 8rem;
    }
    
    @media screen and (max-width: 1600px){
        padding: 4rem 0rem 0rem;
        
    }
    
    @media screen and (max-width: 1200px){
        flex-direction: column;
        
    }


    @media screen and (max-width: 400px){
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info{
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 2000px){
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 1rem 1rem;

    .badge-cmp, .tag-cmp{
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    .tag-cmp{
        flex-direction: column;
        margin-top: 3rem;

        p{
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    span{
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles{
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-top: 5rem;
    margin-bottom: 2rem;
   

    div{
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        img{
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1){
        width: 100px;
        height: 100px;
    }
    div:nth-child(2){
        margin: 1.75rem;
        width: 140px;
        height: 140px;
    }
    div:nth-child(3){
        width: 70px;
        height: 70px;
    }

    @media screen and (min-width: 2000px) {
        div:nth-child(1){
            width: 160px;
            height: 160px;
        }
        div:nth-child(2){
            margin: 1.75rem;
            width: 300px;
            height: 300px;
        }
        div:nth-child(3){
            width: 120px;
            height: 120px;
        }
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        //justify-content: center;
        margin-left: 0;
        margin-top: 0;

        div{
            margin: 1rem;
        }

        div:nth-child(1){
            width: 90px;
            height: 90px;
        }
        div:nth-child(2){
            margin: 1.75rem;
            width: 120px;
            height: 120px;
        }
        div:nth-child(3){
            width: 60px;
            height: 60px;
        }
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        //justify-content: center;
        margin-left: 0;
        margin-top: 0;
        margin-left: 5rem;
        margin-right: 2rem;

        div{
            margin: 1rem;
        }

        div:nth-child(1){
            width: 80px;
            height: 80px;
        }
        div:nth-child(2){
            margin: 1.75rem;
            width: 100px;
            height: 100px;
        }
        div:nth-child(3){
            width: 55px;
            height: 55px;
        }  
        
    }
}

.app__header-img{
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 0%;
    

    img{
        width: 85%;
        object-fit: contain;
        z-index: 1;
    }

    .overlay_circle{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
}