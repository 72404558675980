.app__works{
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__work-filter{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__work-filter-item{
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover{
            background-color: var(--secondary-color);
            color: #fff;
        }

        @media screen and (min-width: 2000px){
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
    }
    .filter-active{
        background-color: var(--secondary-color);
        color: #fff;
    }

}

.app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item{
        width: 300px;
        flex-direction: column;
    
        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        transition: all 0.3s ease;

        &:hover{
            box-shadow: 0 0 25px rgba(0,0,0,0.2);
        }
    }
}

.app__work-img{
    width: 100%;
    height: 230px;
    
    position: relative;

    .project-img {
        border-radius: 2rem;
        width: 100%;
        height: 100%;
    }
}

.btn-container {
    display: flex;
    justify-content: center;
    gap: 1rem;

    
    .btn {
        font-weight: 600;
        transition: all 300ms ease;
        padding: 1rem;
        width: 8rem;
        border-radius: 2rem;
        border: rgb(53, 53, 53) 0.1rem solid;
        border-color: rgb(163, 163, 163);
        text-decoration: none;

        
        &:hover{
            background-color: var(--secondary-color);
            color: #fff;
            cursor: pointer;
        }
        
        
    }
    .btn-color-2,
    .btn-color-1:hover {
        background: var(--secondary-color);
        color: white;
    }

    .btn-color-2:hover {
        background: var(--primary-color);
        color: var(--secondary-color);
    }
    
}
  
.app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    h4{
        margin-top: 1rem;
        line-height: 1.5;
    }

    .app__work-tag {
        position: absolute;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: #fff;
        top: -25px;

        p{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 0.5rem;
            font-weight: 500;
        }
    }
}
  
